.App {
  text-align: center;
  

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-headshot{

    border-radius: 50%;
    height: 30vh;
    border: 5px solid  #a80538;
   

}
body{
  /* background-image: url("images/paper_bg.png");
  background-repeat: repeat;
  background-size: 72px; */
  /* background-color: #b4c6ac; */
  background: rgb(180,198,172);
  background: linear-gradient(180deg, rgba(180,198,172,1) 0%, rgba(213,227,210,0.7203256302521008) 100%);

}
html, body, #app, #app>div {
  height: 100%
}

.App-header {
  /* background-image: url("images/paper_bg.png");
  background-repeat: repeat;
  background-size: 72px; */
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top:5vh;
  padding-bottom:5vh;
}
.App-content {

  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
}
.linkBlock{
  width: 50%;
  height: 10vh;
  line-height: 6vh;
  border: 5px solid  #a80538;
  text-align: center;
  vertical-align: middle;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 900;
  font-size: 2vh;
  color: #683b11;
  background-color:  #efe1d5;
  margin-bottom: 1vh;
  border-radius: 60px;
  box-shadow: 3px 2px 5px #505e51;
}
.footer{
  margin-top:2vh;
  margin-bottom:2vh;
}
.modal {
  background: rgba(0, 0, 0, 0.6);
}
.modal-container {
  position: fixed;
    width: 80%;
    background: #ffffff;
    left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
    padding: 20px;
    height: 50%;
    border: 4px solid #a80538;
}
.footer-logo{
  height: 20vmin;
  pointer-events: none;

}
.ig-logo{
  width: 50px;
  color: white;
  margin-top: 1px;
  margin-right: 25px;
margin-left: 25px;
}
.ig-logo-bg{
  background-color:rgb(194, 159, 154);
  width: 48px;
  height: 48px;
  margin: auto;
  border-radius: 12px;
}
.App-link {
  color: #61dafb;
}
.d-block{
  display: block;
}
.d-none{
  display:none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .linkBlock{
    width: 90%;
    height: 8vh;
    line-height: 6vh;
    border: 4px solid  #a80538;
    text-align: center;
    vertical-align: middle;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 800;
    font-size: 1.6vh;
    color: #683b11;
  background-color:  #efe1d5;
    margin-bottom: 1vh;
    box-shadow: 3px 2px 5px #505e51;
  }
  .App-headshot {
    border-radius: 50%;
    height: 25vh;
    border: 5px solid #a80538;
}
.App-header {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* padding-top: 5vh; */
  /* padding-bottom: 5vh; */
}
  .modal-container {
    position: fixed;
      width: 70%;
      background: #ffffff;
      left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
      padding: 20px;
      height: 50%;
      border: 4px solid #a80538;
  }
}